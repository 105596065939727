import React, { Suspense, useEffect, useState, lazy } from "react";

// Libraries
import { Routes, Route, useLocation } from "react-router-dom";
import retina from "retinajs";
import { AnimatePresence } from "framer-motion";

// Context
import GlobalContext from "./Context/Context";

// Components
import ScrollToTopButton from "./Components/ScrollToTop"

import Test from "./Pages/Home/Test";
import HakkimizdaPages from "./Pages/Kayabasi/HizmetlerimizPages";



// Home

const HomeBusinessPage = lazy(() => import("./Pages/Home/Business"))
const Anasayfa = lazy(() => import("./Pages/Kayabasi/Anasayfa"))

const Kurumsal = lazy(() => import("./Pages/Kayabasi/Kurumsal"))
const Form = lazy(() => import("./Pages/Kayabasi/Form"))
const KaliteKontrol = lazy(() => import("./Pages/Kayabasi/KaliteKontrol"))
const Hizmetlerimiz = lazy(() => import("./Pages/Kayabasi/Hizmetlerimiz"))
const HizmetlerimizPages = lazy(() => import("./Pages/Kayabasi/HizmetlerimizPages"))
const Iletisim = lazy(() => import("./Pages/Kayabasi/Iletisim"))


const MedyaMerkezi = lazy(() => import("./Pages/Kayabasi/MedyaMerkezi"))
const OnlineKatalog = lazy(() => import("./Pages/Kayabasi/OnlineKatalog"))
const BizdenHaberler = lazy(() => import("./Pages/Kayabasi/BizdenHaberler"))
const Politikalar = lazy(() => import("./Pages/Kayabasi/Politikalar"))
const Sertifikalar = lazy(() => import("./Pages/Kayabasi/Sertifikalar"))


const NotFoundPage = lazy(() => import("./Pages/404"))




function App() {
  const [headerHeight, setHeaderHeight] = useState(0);
  const [footerHeight, setFooterHeight] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [customModal, setCustomModal] = useState({
    el: null,
    isOpen: false
  })
  const location = useLocation();

  // RetinaJS
  useEffect(() => {
    window.addEventListener('load', retina(document.querySelectorAll('img')));
  }, [])

  useEffect(() => {
    setTimeout(() => {
      import("./Functions/Utilities").then(module => {
        module.SetHeaderMenuPos()
        module.setDocumentFullHeight()
      })
    }, 1000);
  }, [location])

  useEffect(() => {
    if (isModalOpen === true) {
      document.querySelector("body").classList.add("overflow-hidden");
    } else {
      document.querySelector("body").classList.remove("overflow-hidden");
    }
  }, [isModalOpen]);

  // Get the current location and set the window to top
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
    setFooterHeight(0);
    setCustomModal({
      ...customModal,
      el: null,
      isOpen: false
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <GlobalContext.Provider
      value={{
        headerHeight,
        setHeaderHeight,
        footerHeight,
        setFooterHeight,
        isModalOpen,
        setIsModalOpen,
        customModal,
        setCustomModal,
      }}
    >
      <div className="App" style={{ "--header-height": `${headerHeight}px` }}>
        {
          <main style={{ marginTop: headerHeight, marginBottom: footerHeight }}>
            <ScrollToTopButton />
            <AnimatePresence exitBeforeEnter>
              <Suspense fallback={<></>}>
                
                <Routes>
                  <Route path="/" element={<HomeBusinessPage style={{ "--base-color": "#3452ff" }} />}>
                    <Route path="/"  element={<Anasayfa style={{ "--base-color": "#3452ff" }} />}/>
               
                    <Route path="/kurumsal" element={<Kurumsal  style={{ "--base-color": "#3452ff" }}/>} />
                    <Route path="/kalite-kontrol" element={<KaliteKontrol  style={{ "--base-color": "#3452ff" }}/>} />
                    <Route path="/Hizmetlerimiz" element={<Hizmetlerimiz  style={{ "--base-color": "#3452ff" }}/>} />
                      
          

                    <Route path="/iletisim" element={<Iletisim  style={{ "--base-color": "#3452ff" }}/>} />
                    <Route path="/form" element={<Form  style={{ "--base-color": "#3452ff" }}/>} />
                    <Route path="/Hizmetlerimiz/:id" element={<HizmetlerimizPages  style={{ "--base-color": "#3452ff" }}/>} />
                    

                    <Route path="/medya-merkezi" element={<MedyaMerkezi  style={{ "--base-color": "#3452ff" }}/>} />
                    <Route path="/medya-merkezi/online-katalog" element={<OnlineKatalog  style={{ "--base-color": "#3452ff" }}/>} />
                    <Route path="/medya-merkezi/bizden-haberler" element={<BizdenHaberler  style={{ "--base-color": "#3452ff" }}/>} />
                    <Route path="/medya-merkezi/politikalar" element={<Politikalar  style={{ "--base-color": "#3452ff" }}/>} />
                    <Route path="/medya-merkezi/sertifikalar" element={<Sertifikalar  style={{ "--base-color": "#3452ff" }}/>} />

                    <Route path="*"  element={<NotFoundPage style={{ "--base-color": "#3452ff" }}/>} />


                  </Route>
                </Routes>
              </Suspense>
            </AnimatePresence>
          </main>
        }
      </div>
    </GlobalContext.Provider>
  )
}

export default App;