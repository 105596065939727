import React, { useEffect, useLayoutEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import ImageGallery from '../../Components/ImageGallery/ImageGallery'
import { fadeIn } from '../../Functions/GlobalAnimations'
import { Link, redirect, useLocation, useNavigate, useParams } from 'react-router-dom'
import { m } from "framer-motion";


const otomativ = [
    {
        src: "/assets/img//kayabasi/hizmetlerimiz-page/otomativ-yan-sanayi/1.jpg",
        title: "Lightbox gallery image title",
    },
    {
        src: "/assets/img//kayabasi/hizmetlerimiz-page/otomativ-yan-sanayi/2.jpg",
        title: "Lightbox gallery image title",
    },
    {
        src: "/assets/img//kayabasi/hizmetlerimiz-page/otomativ-yan-sanayi/3.jpg",
        title: "Lightbox gallery image title",
    },
    {
        src: "/assets/img//kayabasi/hizmetlerimiz-page/otomativ-yan-sanayi/4.jpg",
        title: "Lightbox gallery image title",
    }

]


const tekstilMakinaları = [
    {
        src: "https://via.placeholder.com/800x989",
        title: "Lightbox gallery image title",
    },
    {
        src: "https://via.placeholder.com/800x989",
        title: "Lightbox gallery image title",
    },
    {
        src: "https://via.placeholder.com/800x989",
        title: "Lightbox gallery image title",
    },
    {
        src: "https://via.placeholder.com/800x989",
        title: "Lightbox gallery image title",
    }

]


const tekstilGeriDonusum = [
    {
        src: "https://via.placeholder.com/800x989",
        title: "Lightbox gallery image title",
    },
    {
        src: "https://via.placeholder.com/800x989",
        title: "Lightbox gallery image title",
    },
    {
        src: "https://via.placeholder.com/800x989",
        title: "Lightbox gallery image title",
    },
    {
        src: "https://via.placeholder.com/800x989",
        title: "Lightbox gallery image title",
    }

]


const kabloMakinalari = [
    {
        src: "https://via.placeholder.com/800x989",
        title: "Lightbox gallery image title",
    },
    {
        src: "https://via.placeholder.com/800x989",
        title: "Lightbox gallery image title",
    },
    {
        src: "https://via.placeholder.com/800x989",
        title: "Lightbox gallery image title",
    },
    {
        src: "https://via.placeholder.com/800x989",
        title: "Lightbox gallery image title",
    }

]




const Content1 = {
  title:'Otomotiv Yan Sanayi Yedek Parça Üretimi',
  link:'/hizmetlerimiz/otomotiv-yan-sanayi-yedek-parca-uretimi',
  img:"/assets/img//kayabasi/hizmetlerimiz-page/otomativ-yan-sanayi/banner.jpg",
  content:'Otomotiv sektörüne özel uluslararası standartlarda yan sanayi yedek parça üretimi gerçekleştiriyoruz.'
}
const Content2 = {
  title:'Tekstil Makineleri Yedek Parça Üretimi',
  link:'/hizmetlerimiz/tekstil-makineleri-yedek-parca-uretimi',
  img:"/assets/img//kayabasi/hizmetlerimiz-page/tekstil-makinalari/banner.jpg",
  content:'Tekstil üreticilerinin ihtiyaç duyduğu kaliteli ve ulaşılabilir yedek parça üretimlerini sektör standartları doğrultusunda net terminler ve teslimatlarla gerçekleştiriyoruz.'
}
const Content3 = 

  {
    title:'Tekstil Geri Dönüşüm Makineleri Yedek Parça Üretimi',
    link:'/hizmetlerimiz/tekstil-geri-donusum-makineleri-yedek-parca-uretimi',
    img:"/assets/img//kayabasi/hizmetlerimiz-page/tekstil-geri-donusum-makinalari/banner.jpg",
    content:'Tekstil sektörünün ham madde konusunda büyük önem verdiği tekstil geri dönüşümü makinelerinin ihtiyaç duyduğu yedek parça üretimini gerçekleştiriyoruz.'
  }

const Content4 = 
  {
    title:'Kablo Makineleri Yedek Parça Üretimi',
    link:'/hizmetlerimiz/kablo-makineleri-yedek-parca-uretimi',
    img:"/assets/img//kayabasi/hizmetlerimiz-page/kablo-makinalari/banner.jpg",
    content:'Kablo üreticilerinin hassas üretimlerinde kullandıkları makinelerin yedek parça üretimlerini uluslararası standartlarda gerçekleştiriyor ve net terminlerle müşterilerimize sunuyoruz.'
  }


  const Content5 = 
  {
    title:'Endüstri Makineleri Yedek Parça Üretimi',
    link:'/hizmetlerimiz/endustri-makineleri-yedek-parca-uretimi',
    img:"/assets/img//kayabasi/hizmetlerimiz-page/kablo-makinalari/banner.jpg",
    content:'Kablo üreticilerinin hassas üretimlerinde kullandıkları makinelerin yedek parça üretimlerini uluslararası standartlarda gerçekleştiriyor ve net terminlerle müşterilerimize sunuyoruz.'
  }



const HizmetlerimizPages = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [data, setData] = useState()
    const [content, setContent] = useState()
    
    useEffect(() => {
        if(id === 'otomotiv-yan-sanayi-yedek-parca-uretimi')
        {
            setData(otomativ)
            setContent(Content1)
        }
        else if(id === 'tekstil-makineleri-yedek-parca-uretimi')
        {
            setData(otomativ)
            setContent(Content2)
        }
        else if(id === 'tekstil-geri-donusum-makineleri-yedek-parca-uretimi')
        {
            setData(otomativ)
            setContent(Content3)
        }
        else if(id === 'kablo-makineleri-yedek-parca-uretimi')
        {
            setData(otomativ)
            setContent(Content4)
        }

        else if(id === 'endustri-makineleri-yedek-parca-uretimi')
        {
            setData(otomativ)
            setContent(Content5)
        }
        else{
          navigate("/hizmetlerimiz");
        }
    }, [navigate])
    
  return (
    <>

          {/* Section Start */}
          <section className=" py-[40px] sm:py-[30px]">
        <Container>
          <Row className="xs:text-center px-[40px]">
            <Col xl={12} lg={6} className="flex md:justify-center md:mb-[15px] sm:block sm:text-center px-[15px] items-center !text-[#454242]">
             <Link to={'/'}>Ana sayfa / </Link>
             <Link to={'/hizmetlerimiz'}> Hizmetlerimiz / </Link>
             <Link to={content&&content.link}>{content&&content.title}</Link>
        
            </Col>

          </Row>
        </Container>
      </section>
      {/* Section End */}
      


      <m.section className="mb-[20px]" {...fadeIn}>
        <Container className='!pr-[30px] md:pl-[30px]'>
        {/* <Row>
            <Col className="mb-[4%]">              
            <h6 className="font-serif text-dark text-center font-medium mb-[25px] lg:mb-[15px]">One third / Two third</h6>
            </Col>
          </Row> */}
          <Row>
            <Col lg={4} className="px-[15px] md:mb-[15px] !mb-20">
            <h5 className="  font-medium  font-serif inline-block px-[40px] sm:px-[30px] text-kayabasiblue !mb-5">{content&&content.title}</h5>
              <div className=" px-[40px] sm:px-[30px]">
                {/* <h5 className="text-darkgray lg:mb-[15px]">1/3</h5> */}
                <p className='text-[#454242] group-hover:!text-white text-xmd leading-6 font-normal md:leading-7 md:font-normal'>{content &&content.content}</p>
              </div>
            </Col>
            <Col lg={8} className="">
              <div className="bg-kayabasiblue h-[331px] w-full ">
                {/* <h5 className="text-darkgray lg:mb-[15px]">2/3</h5> */}
                <img width="" height="" src={content&&content.img} className=" w-full h-full object-cover" alt="" data-no-retina="" />
              </div>
            </Col>
          </Row>
        </Container>
      </m.section>





      <m.section className="  mb-[40px] ">
        <Container >

            <Row>
            <ImageGallery theme="image-gallery-01" data={data} overlay={["#0055a9", "#0055a9", "#0055a9", "#0055a9", "#0055a9"]} className="" animation={fadeIn} />
          </Row>
        </Container>
      </m.section>
    </>
  )
}

export default HizmetlerimizPages